import { dew as _stream_readableDew } from "./lib/_stream_readable.js";
import { dew as _stream_writableDew } from "./lib/_stream_writable.js";
import { dew as _stream_duplexDew } from "./lib/_stream_duplex.js";
import { dew as _stream_transformDew } from "./lib/_stream_transform.js";
import { dew as _stream_passthroughDew } from "./lib/_stream_passthrough.js";
import { dew as _endOfStreamDew } from "./lib/internal/streams/end-of-stream.js";
import { dew as _pipelineDew } from "./lib/internal/streams/pipeline.js";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  exports = exports = _stream_readableDew();
  exports.Stream = exports;
  exports.Readable = exports;
  exports.Writable = _stream_writableDew();
  exports.Duplex = _stream_duplexDew();
  exports.Transform = _stream_transformDew();
  exports.PassThrough = _stream_passthroughDew();
  exports.finished = _endOfStreamDew();
  exports.pipeline = _pipelineDew();
  return exports;
}